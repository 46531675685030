import React from "react"
import PropTypes from "prop-types"

import Box from "./box"
import { alpha } from "@theme-ui/color"

const CalloutBox = ({ children, title, size, color, ...restProps }) => {
  return (
    <Box
      sx={{
        borderRadius: "lg",
        borderWidth: 4,
        borderStyle: "solid",
        borderColor: color,
        bg: alpha(color, 0.3),
      }}
    >
      <Box
        sx={{
          borderRadius: "lg",
          borderWidth: 4,
          borderStyle: "solid",
          borderColor: color,
          fontSize: size,
          position: "relative",
          left: -3,
          top: -3,
          p: 6,
          bg: "background",
        }}
        {...restProps}
      >
        {children}
      </Box>
    </Box>
  )
}

CalloutBox.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
}

CalloutBox.defaultProps = {
  color: "spring",
  size: [3, 3, 4],
}

export default CalloutBox
