import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Breadcrumb from "../../../components/breadcrumb"
import Bullet from "../../../components/bullet"
import BulletList from "../../../components/bullet-list"
import CalloutBox from "../../../components/callout-box"
import Column from "../../../components/column"
import Columns from "../../../components/columns"
import Constrain from "../../../components/constrain"
import Paragraph from "../../../components/paragraph"
import Grid from "../../../components/grid"
import Heading from "../../../components/heading"
import Hyphen from "../../../components/hyphen"
import Image from "../../../components/image"
import Layout from "../../../layouts/default"
import MarkerHighlight from "../../../components/marker-highlight"
import PageTitle from "../../../components/page-title"
import Poem from "../../../components/poem"
import Seo from "../../../components/seo"
import Stack from "../../../components/stack"
import StepsBox from "../../../components/steps-box"
import TextLink from "../../../components/textlink"
import TextPlaceholder from "../../../components/text-placeholder"

const WorkshopKalenderPage = () => {
  const data = useStaticQuery(graphql`
    query WorkshopAugustPageQuery {
      beispiel01: file(
        relativePath: { eq: "workshops/kalender/august/beispiel-1.jpg" }
      ) {
        ...largeImage
      }
      beispiel02: file(
        relativePath: { eq: "workshops/kalender/august/beispiel-2.jpg" }
      ) {
        ...largeImage
      }
      ogImage: file(relativePath: { eq: "og_images/kalender.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1200, height: 600, layout: FIXED)
        }
      }
    }
  `)

  return (
    <Layout backdrop="workshop-kalender-august">
      <Seo
        title="August"
        description="Die Sommerferien sind da. Zeit, zu träumen, zu verreisen oder einfach einmal in den Tag hineinzuleben. Wonach sehnst du dich, wenn du an die Sommerferien denkst? Welche Erinnerungen an vergangene Urlaube oder Ferienerlebnisse kommen dir in den Sinn? Verfasse dazu ein Haiku-Gedicht."
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Digital",
              link: "/digital",
            },
            {
              title: "Workshops",
              link: "/workshops",
            },
            {
              title: "Wörter und Bilder",
              link: "/workshops/kalender",
            },
            {
              title: "August",
              link: "/workshops/kalender/august",
            },
          ]}
        />
        <Stack space={[24, 24, 32]}>
          <Stack>
            <PageTitle>August</PageTitle>
            <Paragraph dropcap={true}>
              Die Sommerferien sind da. Zeit, zu träumen, zu verreisen oder
              einfach einmal in den Tag hineinzuleben. Wonach sehnst du dich,
              wenn du an die Sommerferien denkst? Welche Erinnerungen an
              vergangene Urlaube oder Ferienerlebnisse kommen dir in den Sinn?
              Verfasse dazu ein Haiku-Gedicht.
            </Paragraph>
          </Stack>

          <Constrain>
            <Stack>
              <Heading as="h2" level={2}>
                Was ist ein Haiku?
              </Heading>
              <Paragraph>
                Das Haiku kommt aus Japan und ist die kürzeste Gedichtform der
                Welt. Es besteht aus nicht mehr als 3 Versen. Für die Länge der
                Verse gibt es genaue Vorgaben:
              </Paragraph>
              <BulletList size={[3, 3, 4, 5]}>
                <Bullet>
                  Der 1. Vers hat <MarkerHighlight>5 Silben</MarkerHighlight>
                </Bullet>
                <Bullet>
                  Der 2. Vers hat <MarkerHighlight>7 Silben</MarkerHighlight>
                </Bullet>
                <Bullet>
                  Der 3. Vers hat <MarkerHighlight>5 Silben</MarkerHighlight>
                </Bullet>
              </BulletList>
            </Stack>
          </Constrain>

          <Constrain>
            <Stack>
              <Heading as="h2" level={2}>
                Hat Hölderlin auch Haikus geschrieben?
              </Heading>
              <Paragraph>
                Zu Hölderlins Zeit gab es die Gedichtform des Haikus zwar
                bereits, aber sie war in Deutschland noch wenig verbreitet.
                Dennoch war die Anzahl der Silben in jedem Vers auch für
                Hölderlins Dichtungen ganz entscheidend. Er hat beim Schreiben
                immer genau darauf geachtet und mitgezählt. Dadurch ergibt sich
                in vielen seiner Gedichte ein gleichmäßiger Rhythmus oder ein
                spannendes Muster.
              </Paragraph>
            </Stack>
          </Constrain>

          <Constrain>
            <Stack>
              <Heading as="h2" level={2}>
                Was ist nochmal eine Silbe und woran erkennst du sie?
              </Heading>
              <Paragraph>
                Manche Wörter bestehen nur aus einer Silbe (z.B. Eis oder Tee).
                Manche Wörter bestehen aber auch aus mehreren Silben (z.B.
                Ur-laub oder Fe-ri-en). Du erkennst sie daran, dass du zwischen
                den Silben beim Sprechen eine Pause machen kannst. Bei den
                meisten Wörtern beginnt eine neue Silbe mit einem Konsonanten.
                <MarkerHighlight>
                  Das Wort Fe-ri-en-er-in-ne-rung hat zum Beispiel schon 7
                  Silben
                </MarkerHighlight>
                .
              </Paragraph>
            </Stack>
          </Constrain>

          <Constrain>
            <Stack>
              <Heading as="h2" level={2}>
                Ein eigenes Haiku verfassen – so geht das....
              </Heading>
              <Paragraph>
                Am besten, gehst du dabei Schritt für Schritt vor:
              </Paragraph>
              <Stack space={6}>
                <StepsBox step={1}>
                  <Paragraph>
                    Überlege dir ein Ferien-Ereignis, über das du schreiben
                    möchtest. Vielleicht hast du auch ein Foto oder ein
                    Erinnerungsstück, das du beschreiben möchtest?
                  </Paragraph>
                </StepsBox>
                <StepsBox step={2}>
                  <Stack>
                    <Paragraph>
                      Ist dir etwas eingefallen? Dann geht es jetzt los. Der 1.
                      Vers ist zugleich der Titel deines Gedichts. Er sollte
                      also angeben, um was es geht. Aber Achtung, hier wird es
                      schon knifflig: Er muss aus genau 5 Silben bestehen.
                    </Paragraph>
                    <Paragraph>
                      Um zu erkennen, wie viele Silben ein Wort hat, sprichst du
                      es dir am besten laut vor und zählst die Silben mit den
                      Fingern mit. Du kannst ein langes Wort verwenden oder auch
                      mehrere kurze Wörter miteinander kombinieren.
                    </Paragraph>
                  </Stack>
                </StepsBox>
                <Stack>
                  <StepsBox step={3}>
                    <Stack space={6}>
                      <Paragraph>
                        Wir haben dir hier eine Haiku-Schablone angelegt. Sie
                        hilft dir dabei, in jedem Vers genau die richtige Anzahl
                        an Silben zu treffen. In jedes Feld kannst du genau eine
                        Silbe schreiben. Ist der 1. Vers gefunden, folgen die
                        Wörter für den 2. und 3. Vers.
                      </Paragraph>
                      <TextPlaceholder
                        paragraphs={1}
                        lines={3}
                        words={[[5, 7, 5]]}
                      >
                        {({ line, lineContent }) => (
                          <Columns alignY="center">
                            <Column width={24}>{`${line + 1}`}. Vers</Column>
                            <Column>{lineContent}</Column>
                          </Columns>
                        )}
                      </TextPlaceholder>
                    </Stack>
                  </StepsBox>
                </Stack>
                <StepsBox step={4}>
                  <Paragraph>
                    Das fertige Gedicht kannst du in den Kalender übertragen und
                    dazu ein Foto von deiner Ferien-Erinnerung einkleben oder
                    ein Bild malen.
                  </Paragraph>
                </StepsBox>
              </Stack>
            </Stack>
          </Constrain>

          <Constrain>
            <Stack>
              <Heading as="h2" level={2}>
                Hier einige Beispiele für Haikus
              </Heading>
              <Stack space={6}>
                <CalloutBox color="autumn">
                  <Poem size={[3, 3, 4]}>
                    <p>
                      Wan
                      <Hyphen />
                      der
                      <Hyphen />
                      ruck
                      <Hyphen />
                      sack, rot
                    </p>
                    <p>
                      Zehn Ki
                      <Hyphen />
                      lo, häu
                      <Hyphen />
                      fig ver
                      <Hyphen />
                      flucht
                    </p>
                    <p>
                      Wan
                      <Hyphen />
                      der
                      <Hyphen />
                      ge
                      <Hyphen />
                      fähr
                      <Hyphen />
                      te
                    </p>
                  </Poem>
                </CalloutBox>
                <CalloutBox color="spring">
                  <Poem size={[3, 3, 4]}>
                    <p>
                      Rad
                      <Hyphen />
                      ur
                      <Hyphen />
                      laub mit Zelt
                    </p>
                    <p>
                      Sechs Uhr mor
                      <Hyphen />
                      gens, leuch
                      <Hyphen />
                      tend hell
                    </p>
                    <p>
                      Ein Mor
                      <Hyphen />
                      gen am Meer
                    </p>
                  </Poem>
                </CalloutBox>
                <CalloutBox color="winter">
                  <Poem size={[3, 3, 4]}>
                    <p>
                      Ge
                      <Hyphen />
                      birgs
                      <Hyphen />
                      wan
                      <Hyphen />
                      de
                      <Hyphen />
                      rung
                    </p>
                    <p>
                      Mit je
                      <Hyphen />
                      dem Schritt stei
                      <Hyphen />
                      ni
                      <Hyphen />
                      ger
                    </p>
                    <p>
                      O<Hyphen />
                      ben Gip
                      <Hyphen />
                      fel
                      <Hyphen />
                      glück.
                    </p>
                  </Poem>
                </CalloutBox>
              </Stack>
            </Stack>
          </Constrain>

          <Stack space={6}>
            <Heading as="h2" level={4} align="center">
              So kann deine Kalenderseite aussehen:
            </Heading>
            <Grid columns={[1, 2]} space={3}>
              <Image
                image={data.beispiel01.childImageSharp.gatsbyImageData}
                alt="Detailansicht der fertigen Kalenderseite. Bunte Buchstaben und ein handgeschriebenes Gedicht"
              />
              <Image
                image={data.beispiel02.childImageSharp.gatsbyImageData}
                alt="Ein weitere Detailansicht der fertigen Kalenderseite. Der Buchstabe E ist fokussiert"
              />
            </Grid>
          </Stack>

          <Constrain>
            <Stack>
              <Paragraph>
                Wir freuen uns über ein Bild von deiner Kalenderseite! Lass dir
                von einem Erwachsenen helfen und schicke uns ein Foto an{" "}
                <TextLink href="mailto:hoelderlinturm@tuebingen.de">
                  hoelderlinturm@tuebingen.de
                </TextLink>
              </Paragraph>
            </Stack>
          </Constrain>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default WorkshopKalenderPage
